"use client";

import Typewriter from "typewriter-effect";

const AboutSection = () => {
  return (
    <div
      style={{
        minHeight: "calc(15vw)",
        zIndex: 10,
        overflow: "visible",
      }}
      className="font-secondary mx-auto max-w-4xl lg:px-8 grid grid-cols-1 gap-4 relative"
    >
     
      <div className="m-auto max-w-sm sm:max-w-full text-center z-40 flex flex-col items-center justify-center">
        <h2 className="font-sans text-5xl md:text-6xl font-bold font-primary text-black">
          <span className="bg-gradient-to-r from-blue-600 via-green-500 to-indigo-400 inline-block text-transparent bg-clip-text">
            Discover
          </span>
          and Share Unique Journeys
        </h2>
        <div className="min-h-[100px] mt-4">
          <Typewriter
            options={{
              wrapperClassName:
                "max-w-xl m-auto mt-6 text-xl leading-8 font-medium text-xl text-gray-600 font-sans px-2",
              strings: [
                "Connect Through Shared Experiences",
                "Explore and share unique itineraries with a passionate community.",
              ],
              delay: 20,
              autoStart: true,
              loop: true,
              deleteSpeed: 4,
            }}
          />
        </div>
      </div>
    </div>
  );
};
export default AboutSection;
